
import { Component, Prop, Vue } from "vue-property-decorator";

type Tile = {
  name: string;
  concierge?: {
    image?: {
      url: string;
    };
    concierge_info?: string;
  };
};

@Component
export default class ConciergeComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  get conciergeImage() {
    return this.tile.concierge?.image?.url;
  }

  get conciergeInfo() {
    return this.tile.concierge?.concierge_info;
  }
}
